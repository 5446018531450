<template>
<div class="relative h-screen bg-gray-900 flex flex-col" :style="{
      backgroundImage: 'url(/img/throwbit_bot_background.svg)',
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }">
    <Header />
    <!-- Section de la planète -->
    <div class="flex-1 flex flex-col items-center justify-center overflow-hidden p-4">
        <h1 class="text-4xl font-extrabold text-white mb-4 mt-0 tracking-wide text-center">
            {{ formattedPoints }} TH
        </h1>
        <h4 class=" text-white mb-4 mt-0 tracking-wide text-center">
            Level 1 {{ messageTes }}
        </h4>
        <!-- Planet image with small indicators -->
        <div class="relative mb-4">
            <img src="/img/planete-2.svg" alt="Planet" class="w-48 h-48 md:w-72 md:h-72 mx-auto transform transition-transform duration-300 hover:scale-110 cursor-pointer" @click="incrementProgress" />
            <transition-group name="floating" tag="div">
                <div v-for="(float, index) in floatingNumbers" :key="index" class="absolute text-lg font-bold text-blue-300 floating-text" :style="{ top: float.y + 'px', left: float.x + 'px' }">
                    +{{ float.value }}
                </div>
            </transition-group>
        </div>

        <!-- Barre de progression -->
        <div class="w-full max-w-xs">
            <div class="relative pt-2">
                <div class="flex items-center justify-between">
                    <span class="text-sm text-white">{{ progressPercentage }}%</span>
                    <span class="text-sm text-white">100%</span>
                </div>
                <div class="relative pt-1">
                    <div class="flex">
                        <div class="w-full bg-gray-700 rounded-full">
                            <div class="bg-gradient-to-r from-blue-500 to-purple-500 text-xs font-medium text-blue-100 text-center p-1 leading-none rounded-full" :style="{ width: progressBarWidth, height: '16px' }"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Action buttons -->
        <div class="space-y-4 mt-6">
            <button class="bg-gray-500 text-white font-semibold py-3 px-12 rounded-full w-full max-w-xs hover:bg-gray-400">Active</button>
            <button class="bg-blue-500 text-white font-semibold py-3 px-12 rounded-full w-full max-w-xs hover:bg-blue-400">Collect</button>
        </div>
    </div>

    <!-- Navigation Menu -->
    <nav class="fixed bottom-4 left-0 right-0 bg-[#rgb(65 145 168)] p-3 shadow-lg rounded-t-lg mx-auto" style="width: 90%;">
        <ul class="flex justify-around items-center space-x-4">
            <li class="flex flex-col items-center">
                <a href="/start" class="flex flex-col items-center p-3 rounded-full shadow-lg transition-transform duration-300 hover:scale-105 hover:shadow-lg hover:shadow-blue-500/50 relative text-white">
                    <i class="fas fa-home text-xl text-white"></i>
                    <span class="text-sm text-white">Home</span>
                    <span v-if="isActive('home')" class="absolute inset-0 border-2 border-blue-400 rounded-full"></span>
                </a>
            </li>
            <li class="flex flex-col items-center">
                <a href="/referal" class="flex flex-col items-center p-3 rounded-full shadow-lg transition-transform duration-300 hover:scale-105 hover:shadow-lg hover:shadow-blue-500/50 relative text-white">
                    <i class="fas fa-user-friends text-xl text-white"></i>
                    <span class="text-sm text-white">Friends</span>
                </a>
            </li>
            <li class="flex flex-col items-center">
                <a href="/task" class="flex flex-col items-center p-3 rounded-full shadow-lg transition-transform duration-300 hover:scale-105 hover:shadow-lg hover:shadow-blue-500/50 relative text-white">
                    <i class="fas fa-tasks text-xl text-white"></i>
                    <span class="text-sm text-white">Tasks</span>
                </a>
            </li>
            <li class="flex flex-col items-center">
                <a href="/airdrop" class="flex flex-col items-center p-3 rounded-full shadow-lg transition-transform duration-300 hover:scale-105 hover:shadow-lg hover:shadow-blue-500/50 relative text-white">
                    <i class="fas fa-gift text-xl text-white"></i>
                    <span class="text-sm text-white">Airdrop</span>
                </a>
            </li>
        </ul>
    </nav>
</div>
</template>

<script>
import Header from "@/components/Header.vue";

export default {
    components: {
        Header
    },
    data() {
        return {
            points: 0,
            maxPoints: 7000,
            floatingNumbers: [],
            messageTes: "Colo"

        };
    },
    computed: {
        formattedPoints() {
            return this.points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        },
        progressBarWidth() {
            return `${(this.points / this.maxPoints) * 100}%`;
        },
        progressPercentage() {
            return Math.round((this.points / this.maxPoints) * 100);
        },
    },
    methods: {
        incrementProgress() {
            if (this.points < this.maxPoints) {
                const pointsGained = 10;
                this.points += pointsGained;

                // Create floating number effect
                const randomX = Math.random() * 80 + 10;
                const randomY = Math.random() * 80 + 10;

                this.floatingNumbers.push({
                    value: pointsGained,
                    x: randomX,
                    y: randomY
                });
                setTimeout(() => {
                    this.floatingNumbers.shift();
                }, 10);
            }
        },
        isActive(page) {
            return page === 'home';
        },
    },
    mounted() {

    }
};
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

.floating-enter-active,
.floating-leave-active {
    transition: opacity 1s, transform 1s;
}

.floating-enter,
.floating-leave-to {
    opacity: 0;
    transform: translateY(-20px);
}

.floating-text {
    position: absolute;
    animation: rise 1s ease forwards;
}

@keyframes rise {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-30px);
    }
}
</style>
