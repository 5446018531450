import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import GameRules from '@/views/GameRules.vue'
import StartPlaying from '@/views/StartPlaying.vue'
import Referal from '@/views/Referal.vue'
import Tasks from '@/views/Tasks.vue'
import Airdrop from '@/views/Airdrop.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },

  {
    path: '/game-rules',
    name: 'game-rules',
    component: GameRules
  },

  {
    path: '/start',
    name: 'start',
    component: StartPlaying
  },

  {
    path: '/referal',
    name: 'referal',
    component: Referal
  },

  {
    path: '/task',
    name: 'task',
    component: Tasks
  },

  {
    path: '/airdrop',
    name: 'airdrop',
    component: Airdrop
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
