<template>
<div class="relative h-screen bg-gray-900 flex flex-col" :style="{
      backgroundImage: 'url(/img/throwbit_bot_background.svg)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }">
    <Header />
    <div class="flex-1 flex flex-col items-center justify-center p-4">
        <img src="/img/planete-2.svg" alt="Planet" class="w-48 h-48 md:w-72 md:h-72 mx-auto transform transition-transform duration-300 hover:scale-110 cursor-pointer animate-pulse" ref="planetImage" />

        <!-- Message d'erreur -->
        <div v-if="errorMessage" class="text-red-500">{{ errorMessage }}</div>

        <!-- Task list -->
        <div class="w-full max-w-2xl">
            <ul class="space-y-4">
                <li v-for="item in dataTasks" :key="item.id" class="bg-gray-800 rounded-full p-4 flex flex-col shadow-lg">
                    <h2 class="text-white font-bold">{{ item.title }}</h2>
                    <p class="text-gray-400">{{ item.description }}</p>
                    <p class="text-green-500">Points: {{ item.points }}</p>
                    <a :href="item.url" class="text-blue-500" target="_blank">Voir plus</a>
                    <small class="text-gray-500">Créé le: {{ new Date(item.createdAt).toLocaleString() }}</small>
                </li>
            </ul>

            <!-- Input field for email -->
            <div v-if="showEmailInput" class="mt-4 relative max-w-md">
                <input type="email" v-model="email" placeholder="Enter your email" class="p-3 w-full bg-gray-700 text-white rounded-lg pr-12" />
                <span @click="submitEmail" class="absolute inset-y-0 right-3 flex items-center cursor-pointer">
                    <i class="fas fa-paper-plane text-blue-500 hover:text-blue-700"></i>
                </span>
            </div>
        </div>
    </div>

    <!-- Navigation Menu -->
    <nav class="fixed bottom-4 left-0 right-0 bg-[#rgb(65 145 168)] p-3 shadow-lg rounded-t-lg mx-auto" style="width: 90%">
        <ul class="flex justify-around items-center space-x-4">
            <li class="flex flex-col items-center">
                <a href="/start" class="flex flex-col items-center p-3 rounded-full shadow-lg relative text-white">
                    <i class="fas fa-home text-xl text-white"></i>
                    <span class="text-sm text-white">Home</span>
                </a>
            </li>
            <li class="flex flex-col items-center">
                <a href="/referal" class="flex flex-col items-center p-3 rounded-full shadow-lg relative text-white">
                    <i class="fas fa-user-friends text-xl text-white"></i>
                    <span class="text-sm text-white">Friends</span>
                </a>
            </li>
            <li class="flex flex-col items-center">
                <a href="/task" class="flex flex-col items-center p-3 rounded-full shadow-lg relative text-white">
                    <i class="fas fa-tasks text-xl text-white"></i>
                    <span class="text-sm text-white">Tasks</span>
                </a>
            </li>
            <li class="flex flex-col items-center">
                <a href="/airdrop" class="flex flex-col items-center p-3 rounded-full shadow-lg relative text-white">
                    <i class="fas fa-gift text-xl text-white"></i>
                    <span class="text-sm text-white">Airdrop</span>
                </a>
            </li>
        </ul>
    </nav>
</div>
</template>

<script>
import UserTasksService from "@/api/service/userTasksService";
import Header from "@/components/Header.vue";

const userServiceTasks = new UserTasksService();

export default {
    components: {
        Header,
    },
    data() {
        return {
            dataTasks: [],
            showEmailInput: false,
            email: "",
            errorMessage: "", // État pour gérer les erreurs
        };
    },
    async mounted() {
        try {
            const tasks = await this.tasksList();
            this.dataTasks = tasks || []; // Initialiser dataTasks
        } catch (error) {
            console.error("Erreur réseau :", error.message);
        }
    },
    methods: {
        toggleEmailInput() {
            this.showEmailInput = !this.showEmailInput;
        },
        submitEmail() {
            if (this.email) {
                alert(`Email submitted: ${this.email}`);
                this.showEmailInput = false;
            } else {
                alert("Please enter a valid email.");
            }
        },
        async tasksList() {
            try {
                const response = await userServiceTasks.getTasks();
                console.log(response); // Afficher la réponse complète
                if (!response || !response.data) {
                    throw new Error("Aucune donnée reçue.");
                }
                return response.data;
            } catch (error) {
                this.errorMessage = "Erreur lors de la récupération des tâches : " + error.message; // Plus de détails sur l'erreur
                console.error("Erreur lors de la récupération des tâches :", error);
                throw error;
            }
        }

    },
};
</script>

<style scoped>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css");
</style>
