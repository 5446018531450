import axiosClient from "../axios"; // Assurez-vous que le chemin est correct

class UserTasksService {
    // Méthode pour récupérer les tâches
    async getTasks() {
        try {
            const response = await axiosClient.get("/get-all-task"); // Utilisez le chemin relatif
            if (response.status === 200) {
                return response.data; // Retourne les données en cas de succès
            } else {
                throw new Error('Erreur lors de la récupération : ' + response.data.message);
            }
        } catch (error) {
            console.error("Erreur dans getTasks:", error);
            throw error; // Relance l'erreur pour la gestion dans le composant
        }
    }
}

export default UserTasksService;
