import axios from 'axios';

// Instance d'axios avec la base URL définie dans les variables d'environnement
const axiosClient = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'accept: */*',
    }
});

export default axiosClient;
